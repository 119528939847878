import * as React from "react";

import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import ClientForm from "../components/ClientForm";

import './ClientAdd.css';

function ClientAdd(props) {

    return (
        <div className="ClientAdd app__main">
            <Overview title={t('newClient')}>
                <ClientForm key={Math.random()*9999} history={props.history}/>
            </Overview>
        </div>
    );
}

export default ClientAdd;
