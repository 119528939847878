import * as React from "react";
import Forms from "./Forms";
import t from "../hooks/useTranslation";

import "./UserDefinedInputItemForm.css";

function UserDefinedInputItemForm({value, onChange, index}) {

    const dataTypeOptions = [
        {label: t('string'), value: 'string'},
        {label: t('int'), value: 'int'},
        {label: t('float'), value: 'float'},
        // {label: t('combobox'), value: 'select'}
    ];

    const onChangeUserInputItem = e => {
        let newValue = value;
        newValue[e.target.name] = e.target.value;
        onChange(index, newValue);
    }

    return <div className="UserDefinedInputItemForm">
        <Forms.Text label={t('label')} value={value.name} onChange={onChangeUserInputItem} name='name'/>
        <Forms.Text label={t('desc')} value={value.desc} onChange={onChangeUserInputItem} name='desc'/>
        <Forms.Select label={t('dataType')} value={value.dataType} options={dataTypeOptions}
                      onChange={onChangeUserInputItem} name='dataType'/>
    </div>;

}

export default UserDefinedInputItemForm;