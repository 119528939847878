import * as React from "react";

import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import MachineForm from "../components/MachineForm";

import './MachineAdd.css';

function MachineAdd(props) {

    return (
        <div className="MachineAdd app__main">
            <Overview title={t('newMachine')}>
                <MachineForm history={props.history}/>
            </Overview>
        </div>
    );
}

export default MachineAdd;
