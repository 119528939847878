import React, {useState} from "react";
import t from "../hooks/useTranslation";
import Button from "../form/Button";
import Error from "./Error";
import Forms from "../form/Forms";
import {download} from "../tools";

import "./DownloadFullModalContent.css";

function DownloadFullModalContent({photo, closeModal}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [fullWithViewer, setFullWithViewer] = useState(false);
    const [fullWithOriginals, setFullWithOriginals] = useState(false);

    const handleSave = async () => {
        try {
            await download(photo, 'full', setLoading, fullWithViewer, fullWithOriginals);
            closeModal();
        } catch (e) {
            setLoading(false);
            console.log(e);
            setError(e);
        }
    }

    return (
        <div className="DownloadFullModalContent">
            <div className="body">
                <div>
                    <div className="row">
                        <Forms.Checkbox style={{fontSize: 12}} label={t('fullWithOriginals')}
                                        value={fullWithOriginals} colWidth={100}
                                        onChange={(e) => setFullWithOriginals(e.target.checked)}
                        />
                        <Forms.Checkbox style={{fontSize: 12}} label={t('fullWithViewer')}
                                        value={fullWithViewer} colWidth={100}
                                        onChange={(e) => setFullWithViewer(e.target.checked)}
                        />
                    </div>
                </div>
                <div className="error">
                    {error && <Error error={error}/>}
                </div>
            </div>
            <div className="footer">
                <Button onClick={closeModal} label={t("close")} dark disabled={loading}/>
                <Button onClick={handleSave} label={t("download")} disabled={loading}/>
            </div>
        </div>
    );
}

export default DownloadFullModalContent;