import * as React from "react";
import useFetch from "../hooks/useFetch";
import config from "../config/config";
import Loading from "../components/Loading";
import Error from "../components/Error";
import t from "../hooks/useTranslation";
import Overview from "../components/OverviewTable/Overview";
import Forms from "../form/Forms";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuthService from "../AuthService";
import {useEffect, useState} from "react";
import moment from 'moment';

import './AppConfig.css';

const authService = new AuthService();

function AppConfig(props) {
    const [file, setFile] = useState();
    const [isNotificationLoading, setIsNotificationLoading] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [machineApiKey, setMachineApiKey] = useState();
    const [machineList, setMachineList] = useState();
    const [userList, setUserList] = useState();
    const [notificationList, setNotificationList] = useState([]);
    const [notifyWhere, setNotifyWhere] = useState([]);
    const [targetUserList, setTargetUserList] = useState([]);
    const {isLoading, error, setError, response} = useFetch(config.apiRouteMap.appConfig);
    const [uploadError, setUploadError] = useState();
    const [uploadProgress, setUploadProgress] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [message, setMessage] = useState('');

    const handleUploadProgress = progressEvent => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
        }
    }

    useEffect(() => {
        const loadMachineList = async () => {
            try {
                const {data} = await authService.post(config.apiRouteMap.listMachines);
                if (data.list) {
                    const list = data.list
                        .map(machine => ({
                            label: `${machine.name} (${machine.client.name})`,
                            value: machine.apiKey
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label));
                    setMachineList(list);
                }
            } catch (e) {
                setError(e);
            }
        }
        const loadUserList = async () => {
            try {
                const {data} = await authService.get(config.apiRouteMap.usersOverview);
                if (data.list) {
                    const list = data.list
                        .map(user => ({
                            label: `${user.name} ${user.surname}`,
                            value: user._id
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label));
                    setUserList(list);
                }
            } catch (e) {
                setError(e);
            }
        }
        const loadNotificationList = async () => {
            try {
                const {data} = await authService.get(config.apiRouteMap.listNotifications);
                if (data) {
                    setNotificationList(data);
                }
            } catch (e) {
                setError(e);
            }
        }
        loadUserList();
        loadMachineList();
        loadNotificationList();
    }, []);

    const call = async (url) => {
        try {
            await authService.fetch(url, "POST");
            alert("Odesláno")
            setError(null);
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    const onChangeFile = (event) => {
        setFile(event.target.files[0]);
    }

    const createNotification = async () => {
        setIsNotificationLoading(true);
        setNotificationError(null);
        try {
            const notification = {
                message,
                email: notifyWhere.indexOf('email') !== -1,
                app: notifyWhere.indexOf('app') !== -1,
                targetUserList
            };
            await authService.post(config.apiRouteMap.notification, notification);

            setNotificationError(null);
            alert(t('notificationCreated'));
            const notificationListTmp = [...notificationList];
            notification.createdBy = {name: 'já', surname: ''};
            notificationListTmp.push(notification);
            setNotificationList(notificationListTmp);
        } catch (err) {
            setNotificationError(err);
        } finally {
            setIsNotificationLoading(false);
        }
    }

    const handleSubmit = async () => {
        setIsUploading(true);
        try {
            const data = new FormData();
            data.append('data', file);
            await authService.post(config.apiRouteMap.upload360, data, {'x-api-key': machineApiKey}, handleUploadProgress);

            setUploadError(null);
            alert(t('uploadFinished'));
        } catch (err) {
            setUploadError(err);
        } finally {
            setIsUploading(false);
        }
    }

    const renderUploadProgress = () => {
        if (isUploading) {
            if (uploadProgress === 100) {
                return (<label>{t('serverInProcess')}</label>);
            } else {
                return (<label>{t('uploadProgress')}: {uploadProgress}%</label>);
            }
        }
    }

    const handleUserListChange = (e) => {
        let list = [...targetUserList];
        if (e.target.checked) {
            list.push(e.target.value);
        } else {
            list = list.filter(item => item !== e.target.value);
        }
        setTargetUserList(list);
    }

    const handleNotifyWhereChange = (e) => {
        let list = [...notifyWhere];
        if (e.target.checked) {
            list.push(e.target.value);
        } else {
            list = list.filter(item => item !== e.target.value);
        }
        setNotifyWhere(list);
    }

    const checkboxesRenderer = (option, checked, onChange) => {
        return <>
            <input checked={checked} onChange={onChange}
                   type="checkbox" name={option.value} value={option.value}/>
            <span>{option.label}</span>
        </>;
    }

    const renderNotificationList = () => {
        const rows = notificationList.map(notification => <>
                <tr>
                    <td>{`${notification.createdBy.name} ${notification.createdBy.surname}`}</td>
                    <td>{notification.email ? t('yes') : t('no')}</td>
                    <td>{notification.app ? t('yes') : t('no')}</td>
                    <td>{notification.message}</td>
                    <td>{moment(notification.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                </tr>
            </>
        );

        if (notificationList.length) {
            return <>
                <p>Přehled notifikací:</p>
                <table className="notification-table">
                    <thead>
                    <tr>
                        <th>Vytvořil</th>
                        <th>E-mail</th>
                        <th>Web</th>
                        <th>Zpráva</th>
                        <th>Datum vytvoření</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </>;
        }
    }

    const renderContent = () => {
        if (error) {
            return <Error error={error}/>;
        } else {
            return (
                <Overview
                    isLoading={isLoading}
                    introduction={t('introductionText')}
                    title={t('appConfigAdministration')}
                    error={error}
                >

                    {response.appConfig && <div className="AppConfigOverview">
                        <p>{t('emailSettings')}</p>
                        <div className="row">
                            <label>{t('invoiceEmails')}:</label>
                            <label>{response.appConfig.invoiceEmailList?.join(", ")}</label>
                            {process.env.NODE_ENV === "development" &&
                            <FontAwesomeIcon onClick={() => call(config.apiRouteMap.processInvoices)} icon="paper-plane"
                                             size="1x"/>}
                        </div>
                        <div className="row">
                            <label>{t('reportEmails')}:</label>
                            <label>{response.appConfig.reportEmailList?.join(", ")}</label>
                            {process.env.NODE_ENV === "development" &&
                            <FontAwesomeIcon icon="paper-plane"
                                             onClick={() => call(config.apiRouteMap.processReporting)}
                                             size="1x"/>}
                        </div>
                        <div className="row">
                            <Forms.Button label={t('edit')}
                                          onClick={() => props.history.push(config.routeMap.editAppConfig)}/>
                        </div>
                    </div>}

                    {userList && <div className="AppConfigOverview">
                        <p>{t('notificationOverview')}</p>
                        <div className="row userList">
                            <label>{t('selectUsers')}:</label>
                            <Forms.Checkboxes value={targetUserList} name='userList' disabled={isNotificationLoading}
                                              onChange={handleUserListChange} options={userList}
                                              contentRenderer={checkboxesRenderer}/>
                        </div>
                        <div className="row">
                            <label>{t('notifyWhere')}:</label>
                            <Forms.Checkboxes value={notifyWhere} name='notifyWhere' disabled={isNotificationLoading}
                                              onChange={handleNotifyWhereChange}
                                              options={[
                                                  {label: t('notifyByEmail'), value: 'email'},
                                                  {label: t('notifyToWebApp'), value: 'app'}
                                              ]}/>
                        </div>
                        <div className="row">
                            <label>{t('message')}:</label>
                            <textarea value={message} name='message' disabled={isNotificationLoading}
                                      onChange={e => setMessage(e.target.value)}/>
                        </div>
                        <div className="row">
                            {notificationError && <Error error={notificationError}/>}
                            <Forms.Button label={t('create')} onClick={createNotification}
                                          disabled={isNotificationLoading}/>
                        </div>
                        {renderNotificationList()}
                    </div>}

                    {machineList && <div className="AppConfigOverview">
                        <p>{t('manual360upload')}</p>
                        <div className="row">
                            <label>{t('selectMachine')}:</label>
                            <Forms.Select value={machineApiKey} name='machine' disabled={isLoading}
                                          onChange={e => setMachineApiKey(e.target.value)} options={machineList}/>
                        </div>
                        <div className="row">
                            <label>{t('selectFile')}:</label>
                            <Forms.File name="file" onChange={onChangeFile}
                                        accept={"zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"}/>
                        </div>
                        {renderUploadProgress()}
                        <div className="row">
                            <Forms.Button label={t('upload')} onClick={handleSubmit} disabled={isUploading}/>
                        </div>
                    </div>}

                    {uploadError && <Error error={uploadError}/>}
                    <div className="AppConfigOverview">
                        <p>{t('diskInfo')}</p>
                        <div className="row">
                            <label>{t('diskCapacity')}:</label>
                            <label>{`${response.diskStats.sizeGB.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} GB`}</label>
                        </div>
                        <div className="row">
                            <label>{t('diskUsage')}:</label>
                            <label>{`${response.diskStats.usedGB.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} GB`}</label>
                        </div>
                        <div className="row">
                            <label>{t('diskFree')}:</label>
                            <label>{`${response.diskStats.availableGB.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} GB`}</label>
                        </div>
                    </div>

                </Overview>
            );
        }
    }

    return (
        <div className="AppConfig app__main">
            {isLoading ? <Loading/> : renderContent()}
        </div>
    );
}

export default AppConfig;
