import Forms from "./Forms";
import Button from "./Button";
import t from "../hooks/useTranslation";
import {useEffect, useState} from "react";
import {ReactComponent as SettingsIcon} from '../img/ico-delete.svg';

import './List.css';

function List(props) {
    let [list, setList] = useState(props.value);

    useEffect(() => {
        setList(props.value);
    }, [props.value]);

    const onChange = e => {
        let newList = list;
        const name = e.target.name;
        const index = name.match(/.*_([0-9]+)$/);
        newList[index[1]] = e.target.value;
        props.onChange(null, props.name, newList)
    }

    const addItem = () => {
        const newList = [...list, ""];
        props.onChange(null, props.name, newList);
    }

    const deleteItem = (i) => {
        const newList = [...list];
        newList.splice(i, 1);
        props.onChange(null, props.name, newList);
    }

    if (!list.length) {
        setList([""]);
    }

    const colWidth = props.colWidth || 50;
    return (
        <div className={`FormList col-${colWidth} ${props.className ? props.className : ''}`}>
            {props.label && <h4>{props.label}</h4>}
            {props.label && <div className="divider"/>}
            <div className="list">
                {list.map((value, i) => (
                        <div key={`FormList_${i}`} className="list-item">
                            <Forms.Text value={value} name={`${props.name}_${i}`} onChange={onChange}/>
                            <SettingsIcon onClick={() => deleteItem(i)}/>
                        </div>
                    )
                )}
            </div>
            <div className="actions">
                <Button label={t("addEmail")} onClick={addItem}/>
            </div>
        </div>
    );
}

export default List;