const config = {
    routeMap: {
        machines: '/machines',
        photos: '/photos',
        clients: '/clients',
        users: '/users',
        appConfig: '/appConfig',
        gpuConfig: '/gpuConfig',

        getPhotoDetail: id => `/photo/edit/${id}`,
        getMachineDetail: id => `/machine/edit/${id}`,
        getClientDetail: id => `/client/edit/${id}`,
        getGpuDetail: id => `/gpu/edit/${id}`,
        getUserDetail: id => `/user/edit/${id}`,

        addUser: '/user/add',
        addMachine: '/machine/add',
        addClient: '/client/add',
        addGpu: '/gpu/add',

        editPhoto: '/photo/edit/:id',
        editUser: '/user/edit/:id',
        editClient: '/client/edit/:id',
        editMachine: '/machine/edit/:id',
        editAppConfig: '/appConfig/edit',
        editGpu: '/gpu/edit/:id'
    },
    apiRouteMap: {
        appConfig: '/api/appConfig',
        gpu: '/api/gpu',
        notification: '/api/notification',
        public: '/public/',
        vehicleMaintenanceOverview: '/api/vehicle-maintenance/dashboard',
        user: '/api/user',
        listMachines: '/api/machine/list',
        listUsers: '/api/user/list',
        listPhotos: '/api/photo/list',
        loadForExport: '/api/photo/loadForExport',
        clientOptions: '/api/client/options',
        machineOptions: '/api/machine/options',
        machinesOverview: '/api/machine/loadOverview',
        usersOverview: '/api/user/loadOverview',
        listNotifications: '/api/notification',
        photosOverview: '/api/photo/loadOverview',
        clientsOverview: '/api/client/loadOverview',
        signIn: '/api/user/signin',
        driver: '/api/driver',
        client: '/api/client',
        upload: '/api/upload',
        upload360: '/api/upload360',
        markPhotoAsChecked: '/api/photo/markAsChecked',
        machine: '/api/machine',
        processInvoices: '/api/cronJob/processMonthlyInvoices',
        processReporting: '/api/cronJob/processWeeklyReporting',
        getPhoto: id => `/api/photo/${id}`,
        getPhotoDetail: id => `/api/photo/detail/${id}`,
        getUser: id => `/api/user/${id}`,
        getClient: id => `/api/client/${id}`,
        getGpu: id => `/api/gpu/${id}`,
        getMachine: id => `/api/machine/${id}`,
        markPhotoToReview: id => `/api/photo/markToReview/${id}`,
        updatePhoto: id => `/api/photo/${id}`,
        markToRephoto: id => `/api/photo/markToRephoto/${id}`,
        uploadUpdated360: id => `/api/photo/update360/${id}`,
        download360: id => `/api/photo/download360/${id}`,
        downloadFull: id => `/api/photo/downloadFull/${id}`,
        downloadOtherPhotos: id => `/api/photo/downloadOther/${id}`,
    },
    menuItems: {
        '/photos': ['/photos', '/photo/edit/[a-f\\d]{24}'],
        '/machines': ['/machines', '/machine/add', '/machine/edit/[a-f\\d]{24}'],
        '/users': ['/users', '/user/edit/[a-f\\d]{24}', '/user/add'],
        '/gpuConfig': ['/gpuConfig', '/gpu/edit/[a-f\\d]{24}', '/gpu/add'],
        '/clients': ['/clients', '/client/add', '/client/edit/[a-f\\d]{24}'],
        '/appConfig': ['/appConfig', '/appConfig/edit'],
    }
};

export default config;