import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";
import {mergeObjects} from "../tools";
import {useParams} from "react-router-dom";

import './GpuForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    uid: '',
    name: '',
    ip: '',
    priority: 1,
    clientList: []
}

function GpuForm(props) {
    let {id} = useParams();
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [clientOptions, setClientOptions] = useState();

    useEffect(() => {
        const init = async () => {
            const {data} = await authService.get(config.apiRouteMap.clientOptions);
            setClientOptions(data.map(({name, _id}) => ({label: name, value: _id})));
        };
        init();
    }, []);

    useEffect(() => {
        if (props.data) {
            const data = mergeObjects(INITIAL_FORM_DATA, props.data);
            setFormData(data);
        }
    }, [props.data]);


    const onSubmit = async () => {
        try {
            setIsLoading(true);


            if (id) {
                await authService.put(config.apiRouteMap.getGpu(id), formData);
            } else {
                const {data} = await authService.post(config.apiRouteMap.gpu, formData);
                id = data._id;
            }

            setError(null);
            setIsLoading(false);
            props.history.push(config.routeMap.gpuConfig);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const handleUserListChange = (e) => {
        let list = [...formData.clientList];
        if (e.target.checked) {
            list.push(e.target.value);
        } else {
            list = list.filter(item => item !== e.target.value);
        }
        setFormData({...formData, clientList: list});
    }

    const onDelete = async () => {
        try {
            setIsLoading(true);
            await authService.delete(config.apiRouteMap.getGpu(id));
            setError(null);
            setIsLoading(false);
            props.history.push(config.routeMap.gpuConfig);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.Text label={t('uid')} value={formData.uid} name='uid'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('name')} value={formData.name} name='name'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                        </div>
                        <div className="row">
                            <Forms.Text label={t('ipAddress')} value={formData.ip} name='ip'
                                        onChange={onChange(setFormData)} disabled={isLoading}/>
                            <Forms.Number label={t('priority')} value={formData.priority} name='priority'
                                          onChange={onChange(setFormData)} disabled={isLoading}/>
                        </div>
                        <div className="row">
                            {
                                clientOptions &&
                                <Forms.Checkboxes value={formData.clientList} name='userList' disabled={isLoading}
                                                  onChange={handleUserListChange} options={clientOptions}
                                                  label={t('assignClients')}/>
                            }
                        </div>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <div>
                        <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                        <Forms.Button label={t('delete')} onClick={onDelete} disabled={isLoading}/>
                    </div>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="GpuForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default GpuForm;