import * as React from "react";
import config from "../config/config";
import t from "../hooks/useTranslation";
import {useEffect, useState} from "react";
import AuthService from "../AuthService";
import {useParams} from "react-router-dom";
import PhotoDetailControlSection from "./PhotoDetailControlSection";
import Modal from "../components/Modal";
import EditPhotoModalContent from "../components/EditPhotoModalContent";
import PhotoDetailMain from "./PhotoDetailMain";
import PhotoShootingModalContent from "../components/PhotoShootingModalContent";

import './PhotoDetail.css';

const authService = new AuthService();

function PhotoDetail(props) {
    let {id} = useParams();
    const [photo360, setPhoto360] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPhotoShootingModal, setShowPhotoShootingModal] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.get(config.apiRouteMap.getPhotoDetail(id));
                setPhoto360(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
            }
        })();
    }, []);

    const delete360 = async () => {
        if (window.confirm(t('confirmDelete'))) {
            try {
                await authService.delete(config.apiRouteMap.getPhoto(photo360._id));
                setLoading(false);
                props.history.push('/photos');
            } catch (err) {
                setLoading(false);
                setError(err);
            }
        }
    }

    const handleModalClick = () => setShowEditModal(!showEditModal);
    const handleHistoryShootingModalOpen = () => setShowPhotoShootingModal(!showPhotoShootingModal);

    return (
        <div className="PhotoDetail app__detail">
            <Modal show={showEditModal} htmlOpenClassName="editPhotoModal"
                   content={<EditPhotoModalContent closeModal={() => setShowEditModal(false)} photo={photo360}/>}
                   modalTitle={t('editPhoto')} isModalOpen={(bool) => setShowEditModal(bool)}/>
            <Modal show={showPhotoShootingModal} htmlOpenClassName="shootingHistoryModal"
                   content={<PhotoShootingModalContent closeModal={() => setShowPhotoShootingModal(false)}
                                                       photo={photo360}/>}
                   modalTitle={t('shootingHistory')} isModalOpen={(bool) => setShowPhotoShootingModal(bool)}/>
            {photo360 && <PhotoDetailMain photo={photo360} handleModalOpen={handleModalClick} delete360={delete360}
                                          handleHistoryShootingModalOpen={handleHistoryShootingModalOpen}
                                          error={error}/>}
            {photo360 && <PhotoDetailControlSection photo={photo360}/>}
        </div>
    );
}

export default PhotoDetail;
