import './Checkbox.css';

function Checkboxes(props) {

    const renderContent = (option, checked, onChange) => {
        if (props.hasOwnProperty('contentRenderer')) {
            return props.contentRenderer(option, checked, onChange);
        } else {
            return (
                <>
                    {option.label}
                    <input checked={checked} onChange={onChange}
                           type="checkbox" name={option.value} value={option.value}/>
                </>
            )
        }
    }

    return (
        <label className="FormCheckboxes">
            {props.label}
            <div className="choices">
                {props.options.map(option => {
                    return (<div key={`${Math.random() * 1000}_${option.value}`}>
                        <label className="choice-label">
                            {renderContent(option, props.value.indexOf(option.value) !== -1, props.onChange)}
                        </label>
                    </div>);
                })}
            </div>
        </label>
    );
}

export default Checkboxes;