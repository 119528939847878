import React from 'react';
import Loading from "../Loading";
import {Link} from "react-router-dom";
import OverviewTableFilters from "./Filters/OverviewTableFilters";

import './OverviewTable.css';

function OverviewTable(props) {
    const columns = props.columns;

    const _renderTableRows = (rows) => {
        return rows?.map((row, i) => {
            let rows = _renderRowColumns(i, row);
            return (
                <tr
                    style={props.rowStyleRenderer ? props.rowStyleRenderer(row) : {}}
                    className={props.rowClassNameRenderer ? props.rowClassNameRenderer(row) : ''}
                >
                    {React.Children.toArray(rows)}
                </tr>
            );
        });
    };

    const _getActions = (row) => {
        const actions = [];
        if (props?.actions?.length) {
            props.actions.forEach((action, i) => {
                const component = action.component(row);
                actions.push(
                    <td className="action">
                        {component}
                    </td>
                );
            });
            return React.Children.toArray(actions);
        }
        return [];
    }

    const buildLink = (renderer, data) => {
        const linkData = renderer(data);
        if (linkData) {
            return <Link to={linkData.route}>{linkData.label}</Link>;
        }
        return null;
    }

    const _renderRowColumns = (i, row) => {
        const result = [];
        // render actions
        columns.forEach(col => {
            let value = row[col.key] || '';

            if (col.linkRenderer) {
                value = buildLink(col.linkRenderer, row)
            } else if (col.valueRenderer) {
                value = col.valueRenderer(row);
            }

            result.push(<td>{value}</td>);
        });
        let actions = _getActions(row);
        result.push(...actions);
        return React.Children.toArray(result);
    };

    const _renderColumnNames = (columns) => {
        const result = [];
        columns.forEach((row, i) => {
            result.push(<th className='header' key={`my_table_th_${i}`}>{row.title}</th>);
        });
        props.rows?.length && props.actions?.forEach((_, i) => result.push(<th className='header'
                                                                               key={`my_table_th_action_${i}`}/>));
        return result;
    };

    const _renderFilter = ({filter, key}) => {
        const filterValue = filter.filterState?.[key]?.value;
        switch (filter.type) {
            case "dateRange":
                return <OverviewTableFilters.DateRange label={filter.label} value={filterValue}
                                                       onChange={val => filter.onChange(key, val, filter.type)}/>
            case "select":
                return <OverviewTableFilters.Select label={filter.label} value={filterValue} options={filter.options}
                                                    emptyOption={filter.emptyOption}
                                                    onChange={newVal => filter.onChange(key, newVal, filter.type)}/>
            default:
                return <OverviewTableFilters.Text label={filter.label} value={filterValue}
                                                  onChange={e => filter.onChange(key, e.target.value, filter.type)}/>
        }
    }

    const _getTableActions = () => {
        return props.tableActions ? <div
            className="app__table__actions">{React.Children.toArray(props.tableActions)}</div> : null;
    };

    const renderTable = () => {
        const content = props.isLoading
            ? <Loading/>
            : (
                <React.Fragment>
                    <div className="app__table__header">{props.title}</div>
                    <div className="app__table__body">
                        <table>
                            <thead>
                            <tr>
                                {React.Children.toArray(_renderColumnNames(columns))}
                            </tr>
                            </thead>
                            <tbody>
                            {React.Children.toArray(_renderTableRows(props.rows))}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            );

        return (
            <section className="app__table">{content}</section>
        );
    }

    const _renderResetFilter = () => {
        if (props.filter && Object.keys(props.filter).length) {
            return <svg className="cross" cursor="pointer" width="24" height="24" xmlns="http://www.w3.org/2000/svg"
                        onClick={props.resetFilter}>
                <path
                    d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/>
            </svg>
        }
    }

    const renderFilter = () => {
        const colListWithFilter = props.columns.filter(col => !!col.filter);
        return colListWithFilter.length ? (
            <section className="app__filter">
                {colListWithFilter.map(col => _renderFilter(col))}
                {_renderResetFilter()}
            </section>
        ) : null;
    }

    return (
        <div className="app__box OverviewTable">
            {_getTableActions()}
            {renderFilter()}
            {renderTable()}
        </div>
    );
}

export default OverviewTable;