import * as React from "react";

import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import UserForm from "../components/UserForm";

import './UserAdd.css';

function UserAdd(props) {

    return (
        <div className="UserAdd app__main">
            <Overview title={t('newUser')}>
                <UserForm history={props.history}/>
            </Overview>
        </div>
    );
}

export default UserAdd;
