import * as React from "react";
import config from "../config/config";
import {Link} from "react-router-dom";
import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/OverviewTable/Overview";
import OverviewTable from "../components/OverviewTable/OverviewTable";
import t from "../hooks/useTranslation";
import moment from "moment";

import './Clients.css';

function Clients() {
    const {
        isLoading,
        error,
        response,
        bricksData
    } = useOverviewData(config.apiRouteMap.clientsOverview, config.apiRouteMap.listClient);

    const columns = [
        {
            key: 'name',
            title: t('client'),
            linkRenderer: ({_id, name, surname}) => ({
                route: config.routeMap.getClientDetail(_id),
                label: name
            })
        },
        {
            key: 'autoUpload',
            title: t('autoUpload'),
            valueRenderer: ({autoUpload}) => autoUpload ? t('yes') : t('no')
        },
        {
            key: 'logo',
            title: t('logo'),
            valueRenderer: ({logo}) => logo &&
                <img style={{maxHeight: "30px"}} src={`/public/${logo}`}
                     alt="logo"/>
        },
        {
            key: 'createdAt',
            title: t('createdAt'),
            valueRenderer: ({createdAt}) => moment(createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getClientDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [<Link className="button secondary" to={config.routeMap.addClient}>{t('newClient')}</Link>];

    return (
        <div className="Clients app__main">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('clientsAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} columns={columns}
                               rows={response?.list}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Clients;
