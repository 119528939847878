import * as React from "react";
import config from "../config/config";

import {Link} from "react-router-dom";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/OverviewTable/Overview";
import OverviewTable from "../components/OverviewTable/OverviewTable";
import t from "../hooks/useTranslation";
import moment from "moment";

import './Clients.css';

function Users() {
    const {
        isLoading,
        error,
        response,
        bricksData
    } = useOverviewData(config.apiRouteMap.usersOverview, config.apiRouteMap.listUsers);

    const columns = [
        {
            key: 'name',
            title: t('client'),
            linkRenderer: ({_id, name, surname}) => ({
                route: config.routeMap.getUserDetail(_id),
                label: `${name} ${surname}`
            })
        },
        {
            key: 'email',
            title: t('email')
        },
        {
            key: 'profiles',
            title: t('profiles'),
            valueRenderer: ({profileList}) => profileList.map(profile => t(`${profile}Profile`)).join(', ')
        },
        {
            key: 'createdAt',
            title: t('createdAt'),
            valueRenderer: ({createdAt}) => moment(createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getUserDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [<Link className="button secondary" to={config.routeMap.addUser}>{t('addUser')}</Link>];

    return (
        <div className="Users app__main">
            <Overview
                isLoading={isLoading}
                title={t('usersAdministration')}
                introduction={t('introductionText')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} columns={columns}
                               rows={response?.list}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Users;
