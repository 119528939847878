import React, {useEffect, useState} from 'react';
import jwtDecode from "jwt-decode";
import AuthService from "../AuthService";
import Loading from "./Loading";
import config from "../config/config";
import {Link} from "react-router-dom";
import history from "../history";
import t from "../hooks/useTranslation";
import {ReactComponent as SettingsIcon} from '../img/ico-logout.svg';

import './LoggedUser.css';

const authService = new AuthService();

function LoggedUser({token, setToken}) {
    const [user, setUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.get(`/api/user/full/${jwtDecode(token)._id}`);
                setUser(data.user);
                if (data.interfaceColors) {
                    localStorage.setItem('interfaceColors', JSON.stringify(data.interfaceColors));
                }
                if (data.logo) {
                    localStorage.setItem('logo', data.logo);
                }
                if (data.profileList) {
                    localStorage.setItem('profileList', data.profileList);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
            }
        })();
    }, []);

    const logout = () => {
        authService.logout();
        history.push('/');
        setToken(null);
    }

    const renderContent = () => {
        return (
            <div className="app__controls">
                <div className="app__controls__user">
                    <Link to={config.routeMap.getUserDetail(user._id)}>
                        <strong>{`${user.name} ${user.surname}`}</strong><br/>{user.email}
                    </Link>
                </div>
                <div className="app__controls__touch logout">
                    <SettingsIcon onClick={logout}/>
                </div>
                <div className="app__controls__menu">
                    <button type="button" aria-controls="nav" aria-label="Zobrazit navigaci" data-open="Menu"
                            data-close="Zavřít" onClick="navigation()" id="nav-toggle">
                        <span className="burger"><span/></span>
                        <span className="stamp">{t('menu')}</span>
                    </button>
                </div>
            </div>
        );
    }

    const getContent = () => {
        return isLoading ? <Loading/> : renderContent();
    }

    return (
        <div className="LoggedUser">
            {getContent()}
        </div>
    );
}

export default LoggedUser;