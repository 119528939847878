import * as React from "react";
import {NavLink, useLocation} from "react-router-dom";
import config from "../config/config";
import t from "../hooks/useTranslation";
import AuthService from "../AuthService";
import {
    getLogoPath,
    getMenuIconColor,
    getNavbarButtonStyles
} from "../tools";
import {ReactComponent as PhotosIcon} from '../img/menu-icon-foto.svg';
import {ReactComponent as MachinesIcon} from '../img/menu-icon-stroje.svg';
import {ReactComponent as ClientsIcon} from '../img/menu-icon-klienti.svg';
import {ReactComponent as UsersIcon} from '../img/menu-icon-uzivatele.svg';
import {ReactComponent as SettingsIcon} from '../img/menu-icon-nastaveni.svg';
import logo from '../img/logo-white.svg';

import './Navbar.css';

const authService = new AuthService();

function Navbar() {
    const {pathname} = useLocation();

    const isActive = (route) => {
        return config.menuItems[route]?.some(regex => {
            return pathname.match(`^${regex}$`);
        });
    }

    const menuItem = (label, route, icon, profileList) => {
        // zkontrolujeme, že uživatel má profil, který je definovaný v profileList
        if (profileList) {
            const userProfiles = authService.getUserProfileList();
            const hasAccess = profileList.some(validProfile => userProfiles.indexOf(validProfile) !== -1);
            if (!hasAccess) {
                return null;
            }
        }

        const isRouteActive = isActive(route);

        const newIcon = React.cloneElement(
            icon,
            {stroke: getMenuIconColor(isRouteActive)}
        );
        return (
            <li>
                <NavLink to={route} activeClassName='selected'
                         isActive={() => isActive(route)} style={getNavbarButtonStyles(isRouteActive)}>
                    {newIcon}
                    <span>{label}</span>
                </NavLink>
            </li>
        );
    }

    const renderLogo = () => {
        const logoPath = getLogoPath();
        if (logoPath) {
            return (
                <img src={`/public/${logoPath}`} alt="logo"/>
            );
        }
    }

    return (
        <aside className="app__navbar" id="nav">
            <div className="logo logo--white">
                <img src={logo} alt="360°"/>
                <span>produktové fotografie</span>
            </div>
            <nav className="app__nav">
                <ul>
                    {menuItem(t('photos'), config.routeMap.photos, <PhotosIcon alt="menu-icon"/>)}
                    {menuItem(t('machines'), config.routeMap.machines, <MachinesIcon alt="menu-icon"/>, ["Admin", "SuperAdmin"])}
                    {menuItem(t('clients'), config.routeMap.clients, <ClientsIcon alt="menu-icon"/>, ['SuperAdmin'])}
                    {menuItem(t('users'), config.routeMap.users, <UsersIcon alt="menu-icon"/>, ['SuperAdmin'])}
                    {menuItem(t('appConfig'), config.routeMap.appConfig, <SettingsIcon alt="menu-icon"/>, ['SuperAdmin'])}
                    {menuItem(t('gpuConfig'), config.routeMap.gpuConfig, <SettingsIcon alt="menu-icon"/>, ['SuperAdmin'])}
                </ul>
            </nav>
        </aside>
    );
}

export default Navbar;
