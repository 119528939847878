import * as React from "react";
import config from "../config/config";
import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import ClientForm from "../components/ClientForm";
import useDetailData from "../hooks/useDetailData";

import './ClientDetail.css';

function ClientDetail(props) {
    const {isLoading, error, response} = useDetailData(config.apiRouteMap.getClient);

    return (
        <div className="ClientDetail app__main">
            <Overview
                isLoading={isLoading}
                title={t('clientDetail')}
                error={error}
            >
                <ClientForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default ClientDetail;
