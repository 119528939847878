import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {inputsContainer, mergeObjects, onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";
import UserDefinedInputsForm from "../form/UserDefinedInputsForm";
import {useParams} from "react-router-dom";

import './MachineForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    uid: '',
    autoUpload: false,
    client: '',
    apiKey: '',
    info: {
        serviceDate: '',
        remaining360CountToService: ''
    },
    statistics: {
        dayUploadCount: 0,
        weekUploadCount: 0,
        monthUploadCount: 0,
        totalUploadCount: 0
    },
    config: {
        deviceModel: '',
        numberOfSteps: 12,
        stepsPerSecond: 1,
        rampNumber: 1,
        catchImageFromUpperCam: false,
        rotateReverse: false,
        upperCamImageFirst: false,
        numberOfPicturesProfiles: [1],
        numberOfPicturesDefault: 1,
        sendOrigImages: false,
        allowAnimation: false,
        allow2D: false,
        image2DPositions: false,
        userDefinedInputs: [],
        deviceType: "GPIO",
        shootingDelay: 100,
        dilateParam: 80,
        blurParam: 333
    }
}

function MachineForm(props) {
    let {id} = useParams();
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        if (props.data) {
            const data = mergeObjects(INITIAL_FORM_DATA, props.data);
            setFormData(data);
        }
    }, [props.data]);

    useEffect(async () => {
        const {data} = await authService.get(config.apiRouteMap.clientOptions);
        setClientList(data);
    }, []);

    const onSubmit = async () => {
        try {
            setIsLoading(true);

            if (id) {
                console.log(formData);
                await authService.put(config.apiRouteMap.getMachine(id), formData);
            } else {
                await authService.post(config.apiRouteMap.machine, formData);
            }
            setError(null);
            props.history.push(config.routeMap.machines);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const getClientOptions = () => {
        return clientList.map(client => ({label: client.name, value: client._id}));
    }

    const deviceTypeOptions = [{
        label: 'GPIO',
        value: 'GPIO'
    }, {
        label: 'SERIAL',
        value: 'SERIAL'
    },]

    const onChangeUserDefinedInputs = newUserDefinedInputs => {
        let newFormData = {...formData};
        newFormData.config.userDefinedInputs = newUserDefinedInputs;
        setFormData(newFormData);
    }

    const numberOfPicturesDefaultOptions = [
        {label: 24, value: 24},
        {label: 48, value: 48},
        {label: 72, value: 72},
        {label: 120, value: 120},
    ];

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <Forms.Text label={t('name')} value={formData.name} name='name' required
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('uid')} value={formData.uid} name='uid' required
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('apiKey')} value={formData.apiKey} name='apiKey'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Select label={t('client')} value={formData.client} name='client'
                                          disabled={isLoading} emptyOption
                                          onChange={onChange(setFormData)} options={getClientOptions()}/>
                        </div>
                        <Forms.NestedForm name='info' disabled={isLoading} value={formData.info}
                                          onChange={onChange(setFormData)} label={t('machineTechnicalInfo')}
                                          inputContainer={inputsContainer}>
                            <Forms.Date label={t('serviceDate')} name='serviceDate' disabled={isLoading}/>
                            <Forms.Text label={t('remaining360CountToService')} name='remaining360CountToService'
                                        disabled={isLoading}/>
                        </Forms.NestedForm>
                        <Forms.NestedForm name='statistics' disabled={isLoading} value={formData.statistics}
                                          onChange={onChange(setFormData)} label={t('machineStatistics')}
                                          inputContainer={inputsContainer}>
                            <Forms.Text label={t('dayPhotoCount')} name='dayUploadCount' disabled/>
                            <Forms.Text label={t('weekPhotoCount')} name='weekUploadCount' disabled/>
                            <Forms.Text label={t('monthPhotoCount')} name='monthUploadCount' disabled/>
                            <Forms.Text label={t('photoCount')} name='totalUploadCount' disabled/>
                        </Forms.NestedForm>
                        <Forms.NestedForm name='config' disabled={isLoading} value={formData.config}
                                          onChange={onChange(setFormData)} label={t('machineConfig')}
                                          inputContainer={inputsContainer}>
                            <Forms.Text label={t('deviceModel')} name='deviceModel'/>
                            <Forms.Number label={t('numberOfSteps')} name='numberOfSteps'/>
                            <Forms.Number label={t('stepsPerSecond')} name='stepsPerSecond'/>
                            <Forms.Number label={t('rampNumber')} name='rampNumber'/>
                            <Forms.Checkbox label={t('catchImageFromUpperCam')} name='catchImageFromUpperCam'/>
                            <Forms.Checkbox label={t('upperCamImageFirst')} name='upperCamImageFirst'/>
                            <Forms.Select options={numberOfPicturesDefaultOptions} label={t('numberOfPicturesDefault')}
                                          name='numberOfPicturesDefault'/>
                            <Forms.Checkbox label={t('rotateReverse')} name='rotateReverse'/>
                            <Forms.Checkbox label={t('sendOriginal')} name='sendOrigImages'/>
                            <Forms.Checkbox label={t('allowAnimation')} name='allowAnimation'/>
                            <Forms.Checkbox label={t('allow2D')} name='allow2D'/>
                            <Forms.Text label={t('image2DPositions')} name='image2DPositions'/>
                            <Forms.Select label={t('deviceType')} name='deviceType' options={deviceTypeOptions}/>
                            <Forms.Number label={t('shootingDelay')} name='shootingDelay'/>
                            <Forms.Number label={t('dilateParam')} name='dilateParam' min={0} max={80}/>
                            <Forms.Number label={t('blurParam')} name='blurParam' min={1} max={333}/>
                        </Forms.NestedForm>
                        <UserDefinedInputsForm label={t('userDefinedInputs')} onChange={onChangeUserDefinedInputs}
                                               value={formData.config.userDefinedInputs}/>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="MachineForm app__box app__box--wide">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default MachineForm;