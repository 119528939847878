import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Loading from "../Loading";

import './OverviewBrick.css';

function OverviewBrick({data, style}) {
    const renderContent = () => {
        return <strong style={style.value}>{data.data}</strong>;
    }

    const renderRoute = () => {
        return (
            <Link className="app__bricks__item pointer" to={data.route} style={style.div}>
                {data.label}<br/>
                {data.isLoading ? <Loading/> : renderContent()}
                <FontAwesomeIcon size={"lg"} icon="chevron-right"/>
            </Link>
        );
    };

    const renderBrick = () => {
        return (
            <div className="app__bricks__item" style={style.div}>
                {data.label}<br/>
                {data.isLoading ? <Loading/> : renderContent()}
            </div>
        );
    };

    return data.route ? renderRoute() : renderBrick();
}

export default OverviewBrick;