import * as React from "react";

import './Select.css';
import {useEffect, useRef, useState} from "react";

function Select(props) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const _handleChange = value => {
        setIsOpen(false);
        props.onChange({target: {name: props.name, value: value}});
    }

    let label = props.label;
    if (props.value !== undefined && props.value !== "") {
        const selectedValue = props.options.find(option => option.value === props.value);
        label = selectedValue?.label;
    }

    const _renderOptions = () => {
        let options = [...props.options];
        options = options.map(opt => <div onClick={() => _handleChange(opt.value)}>{opt.label}</div>)

        if (props.emptyOption) {
            options.unshift(<div className="empty__option" onClick={() => _handleChange(undefined)}/>);
        }
        return React.Children.toArray(options);
    }

    return (
        <div className="Select dropdown col-50" ref={ref}>

            <label onClick={() => setIsOpen(true)}>{props.label}</label>

            <div className={`dropdown__selection ${isOpen ? 'active' : ''}`}
                 onClick={() => setIsOpen(!isOpen)}>
                <label className="label__placeholder">{label}</label>
            </div>

            <div className={`dropdown__options ${!isOpen && 'hide'}`}>
                {_renderOptions()}
            </div>

        </div>
    );
}

export default Select;