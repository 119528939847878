import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {getBrickStyles, getButtonStyles, inputsContainer, onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";
import {mergeObjects} from "../tools";
import {NavLink, useParams} from "react-router-dom";
import {ReactComponent as PhotosIcon} from '../img/menu-icon-foto.svg';
import Button from "../form/Button";
import OverviewBrick from "./OverviewTable/OverviewBrick";

import './ClientForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    autoUpload: false,
    uploadConfig: {
        format: 'zip',
        endpoint: ''
    },
    machineList: [],
    colorSettings: {
        menu: {
            background: ''
        },
        menuItem: {
            background: '',
            text: '',
            icon: ''
        },
        menuItemActive: {
            background: '',
            text: '',
            icon: ''
        },
        buttonLight: {
            background: '',
            text: ''
        },
        buttonDark: {
            background: '',
            text: ''
        },
        buttonActive: {
            background: '',
            text: ''
        },
        buttonDisabled: {
            background: '',
            text: ''
        },
        brick14: {
            desc: '',
            value: '',
            background: ''
        },
        brick2: {
            desc: '',
            value: '',
            background: ''
        },
        brick3: {
            desc: '',
            value: '',
            background: ''
        }
    },
    logo: null,
    apiKey: '',
    allowHosting: false,
    restrictDomains: false,
    deleteOlderThan: 0,
    onlyCheckedPhotos: false,
    allowedRefererList: [],
    invoiceConfig: {
        emailList: [],
        period: 'monthly',
        currency: 'Kč',
        fixPriceAmount: 0,
        pricePerPhoto: 0
    },
    support: {
        name: '',
        phone: '',
        email: ''
    }
}

function ClientForm(props) {
    let {id} = useParams();
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [logo, setLogo] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            const data = mergeObjects(INITIAL_FORM_DATA, props.data);
            setFormData(data);
        }
    }, [props.data]);

    const _handleLogoUpload = async (clientId) => {
        if (logo) {
            const data = new FormData();
            data.append('clientId', clientId);
            data.append('image', logo);
            await authService.post(config.apiRouteMap.upload, data);
        }
    }

    const onSubmit = async () => {
        try {
            setIsLoading(true);

            const tmpData = {...formData};
            tmpData.invoiceConfig.emailList = tmpData.invoiceConfig.emailList.filter(email => email && email);

            if (id) {
                await authService.put(config.apiRouteMap.getClient(id), tmpData);
            } else {
                const {data} = await authService.post(config.apiRouteMap.client, tmpData);
                id = data._id;
            }

            // handle logo
            await _handleLogoUpload(id);

            setError(null);
            setIsLoading(false);
            props.history.push(config.routeMap.clients);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const formatOptions = [
        {label: t('zip'), value: 'zip'},
        {label: t('xml'), value: 'xml'}
    ];

    const onChangeImage = (event) => {
        setLogo(event.target.files[0]);
    }

    const renderButtonPreview = (variant, disabled = false) => (
        <Button disabled={disabled} label={'customText'} style={getButtonStyles(variant, formData.colorSettings)}/>
    );

    const renderMenuItem = (variant) => (
        <NavLink to={window.location.pathname} isActive={() => false} className='navbar-item'
                 style={{height: 32, backgroundColor: formData.colorSettings[variant].background}}>
            <PhotosIcon alt="menu-icon" stroke={formData.colorSettings[variant].icon} height={32}/>
            <label style={{color: formData.colorSettings[variant].text}}>{'Klienti'}</label>
        </NavLink>
    );

    const renderBrickPreview = (i) => {
        return <OverviewBrick key={`overviewBrick${i}`}
                              data={{label: 'test', data: '10'}} style={getBrickStyles(i, formData.colorSettings)}/>
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.Text label={t('name')} value={formData.name} name='name'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Checkbox label={t('autoUpload')} value={formData.autoUpload} name='autoUpload'
                                            disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('apiKey')} value={formData.apiKey} name='apiKey'
                                        onChange={onChange(setFormData)} disabled={isLoading}/>
                            <Forms.Number label={t('deleteOlderThan')} value={formData.deleteOlderThan}
                                          name='deleteOlderThan' onChange={onChange(setFormData)} disabled={isLoading}/>
                            <Forms.File name="logo" label={t('logo')} onChange={onChangeImage} accept={"image/*"}/>
                            {formData.logo && <img style={{maxWidth: "200px", maxHeight: "200px"}}
                                                   src={`/public/${formData.logo}`}
                                                   alt="company-logo"/>}
                            <Forms.Checkbox label={t('allowHosting')} value={formData.allowHosting} name='allowHosting'
                                            onChange={onChange(setFormData)} disabled={isLoading}/>
                            <Forms.Checkbox label={t('onlyCheckedPhotos')} value={formData.onlyCheckedPhotos}
                                            name='onlyCheckedPhotos'
                                            onChange={onChange(setFormData)} disabled={isLoading}/>
                            <Forms.Checkbox label={t('restrictDomains')} value={formData.restrictDomains}
                                            name='restrictDomains'
                                            onChange={onChange(setFormData)} disabled={isLoading}/>
                            <Forms.List name="allowedRefererList" label={t('allowedRefererList')}
                                        value={formData.allowedRefererList} onChange={onChange(setFormData)}/>
                        </div>

                        <Forms.NestedForm name='invoiceConfig' disabled={isLoading} value={formData.invoiceConfig}
                                          onChange={onChange(setFormData)} className="grid" label={t('invoiceConfig')}
                                          inputContainer={inputsContainer}>
                            <Forms.Select name="period" disabled={isLoading} label={t('invoicePeriod')}
                                          options={[{label: t('monthly'), value: 'monthly'}]}/>
                            <Forms.Text name="currency" disabled={isLoading} label={t('currency')}/>
                            <Forms.Number name="fixPriceAmount" disabled={isLoading} label={t('fixPriceAmount')}/>
                            <Forms.Number name="pricePerPhoto" disabled={isLoading} label={t('pricePerPhoto')}/>
                            <Forms.List className={"col-50"} name="emailList" label={t('invoiceEmails')}/>
                        </Forms.NestedForm>

                        <Forms.NestedForm name='support' disabled={isLoading} value={formData.support}
                                          onChange={onChange(setFormData)} className="grid" label={t('support')}
                                          inputContainer={inputsContainer}>
                            <Forms.Text label={t('firstName')} name='name'/>
                            <Forms.Text label={t('mobile')} name='phone'/>
                            <Forms.Text label={t('email')} name='email'/>
                        </Forms.NestedForm>

                        <Forms.NestedForm name='uploadConfig' disabled={isLoading} value={formData.uploadConfig}
                                          onChange={onChange(setFormData)} className="grid" label={t('uploadConfig')}
                                          inputContainer={inputsContainer}>
                            <Forms.Select label={t('format')} options={formatOptions} name='street'
                                          disabled={isLoading}/>
                            <Forms.Text label={t('endpoint')} name='endpoint' disabled={isLoading}/>
                        </Forms.NestedForm>

                        <Forms.NestedForm value={formData.colorSettings} name='colorSettings' disabled={isLoading}
                                          onChange={onChange(setFormData)}>

                            <Forms.NestedForm name='menu' label={t('menuColorSettings')} className="grid"
                                              desc={t('colorDesc')} inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                <div style={{backgroundColor: formData.colorSettings.menu.background}}/>
                            </Forms.NestedForm>

                            <Forms.NestedForm name='menuItem' label={t('menuItemColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                <Forms.Text name='text' label={t('text')}/>
                                <Forms.Text name='icon' label={t('icon')}/>
                                {renderMenuItem('menuItem')}
                            </Forms.NestedForm>

                            <Forms.NestedForm name='menuItemActive' label={t('menuItemActiveColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                <Forms.Text name='text' label={t('text')}/>
                                <Forms.Text name='icon' label={t('icon')}/>
                                {renderMenuItem('menuItemActive')}
                            </Forms.NestedForm>

                            <Forms.NestedForm name='buttonDark' label={t('buttonDarkColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderButtonPreview('buttonDark')}
                                <Forms.Text name='text' label={t('text')}/>
                            </Forms.NestedForm>

                            <Forms.NestedForm name='buttonLight' label={t('buttonLightColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderButtonPreview('buttonLight')}
                                <Forms.Text name='text' label={t('text')}/>
                            </Forms.NestedForm>

                            <Forms.NestedForm name='buttonActive' label={t('buttonActiveColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderButtonPreview('buttonActive')}
                                <Forms.Text name='text' label={t('text')}/>
                            </Forms.NestedForm>

                            <Forms.NestedForm name='buttonDisabled' label={t('buttonDisabledColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderButtonPreview('buttonDisabled', true)}
                                <Forms.Text name='text' label={t('text')}/>
                            </Forms.NestedForm>

                            <Forms.NestedForm name='brick14' label={t('brick14ColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='desc' label={t('desc')}/>
                                <Forms.Text name='value' label={t('value')}/>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderBrickPreview(0)}
                            </Forms.NestedForm>

                            <Forms.NestedForm name='brick2' label={t('brick2ColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='desc' label={t('desc')}/>
                                <Forms.Text name='value' label={t('value')}/>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderBrickPreview(1)}
                            </Forms.NestedForm>

                            <Forms.NestedForm name='brick3' label={t('brick3ColorSettings')}
                                              inputContainer={inputsContainer}>
                                <Forms.Text name='desc' label={t('desc')}/>
                                <Forms.Text name='value' label={t('value')}/>
                                <Forms.Text name='background' label={t('background')}/>
                                {renderBrickPreview(2)}
                            </Forms.NestedForm>

                        </Forms.NestedForm>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="ClientForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default ClientForm;