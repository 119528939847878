import * as React from "react";
import Overview from "../components/OverviewTable/Overview";
import useOverviewData from "../hooks/useOverviewData";
import OverviewTable from "../components/OverviewTable/OverviewTable";
import t from "../hooks/useTranslation";
import {Link} from "react-router-dom";
import config from "../config/config";
import AuthService from "../AuthService";

import './Machines.css';

const authService = new AuthService();

const INITIAL_BRICKS_DATA = [
    {
        label: t('machineCount'),
        isLoading: true,
        dataKey: 'machineCount'
    },
    {
        label: t('machineOnline'),
        isLoading: true,
        dataKey: 'machineOnline'
    },
    {
        label: t('machineInProcess'),
        isLoading: true,
        dataKey: 'machineShooting'
    },
    {
        label: t('machineOffline'),
        isLoading: true,
        dataKey: 'machineOffline',
    }
];

function Machines() {
    const {
        isLoading,
        error,
        response,
        bricksData,
        setFilter,
        filter,
        filterValues
    } = useOverviewData(config.apiRouteMap.machinesOverview, config.apiRouteMap.listMachines, INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'uid',
            title: t('uid')
        },
        {
            key: 'active',
            title: t('active'),
            valueRenderer: ({shooting}) => shooting ? t('yes') : t('no')
        },
        {
            key: 'online',
            title: t('online'),
            valueRenderer: ({online}) => online ? t('yes') : t('no')
        },
        {
            key: 'dayPhotoCount',
            title: t('dayPhotoCount'),
            valueRenderer: ({statistics}) => statistics.dayUploadCount
        }
    ];

    const handleFilterChange = (key, value, type) => {
        let newFilter = {...filter};
        newFilter[key] = {type, value};
        setFilter(newFilter);
    };

    const getClientFilterOptions = () => {
        if (filterValues?.clientList) {
            return filterValues.clientList.map(({name, _id}) => ({label: name, value: _id}));
        }
        return [];
    }

    if (authService.isSuperAdmin()) {
        columns.push({
            key: 'client',
            title: t('client'),
            valueRenderer: row => row.client.name,
            filter: {
                type: 'select',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('client'),
                options: getClientFilterOptions()
            }
        });
    }

    const rowActions = [];
    const tableActions = [];
    if (authService.isSuperAdmin()) {
        tableActions.push(<Link className="button secondary" to={config.routeMap.addMachine}>{t('newMachine')}</Link>);
        rowActions.push({
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getMachineDetail(_id)}>
                {t('detail')}
            </Link>
        });
    }

    return (
        <div className="Machines app__main">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('machineOverview')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={rowActions} columns={columns} rows={response?.list}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Machines;
