import React from "react";
import t from "../hooks/useTranslation";
import Button from "../form/Button";
import moment from 'moment';

import "./PhotoShootingModalContent.css";

function PhotoShootingModalContent({photo, closeModal}) {

    return (
        <div className="PhotoShootingModalContent">
            <div className="body">
                <div className="app__box app__box--wide app__info">
                    <div className="app__info__item">
                        <table>
                            <tbody>
                            <tr>
                                <th>Začátek focení</th>
                                {photo.shootingHistory.map(h => <td>{moment(h.shootingStarted).format('DD.MM.YYYY HH:mm')}</td>)}
                            </tr>
                            <tr>
                                <th>Konec focení</th>
                                {photo.shootingHistory.map(h => <td>{moment(h.shootingEnded).format('DD.MM.YYYY HH:mm')}</td>)}
                            </tr>
                            <tr>
                                <th>Nafoceno fotografií</th>
                                {photo.shootingHistory.map(h => <td>{h.shootsCount}</td>)}
                            </tr>
                            <tr>
                                <th>Status</th>
                                {photo.shootingHistory.map(h => <td>{t(`${h.status}Status`)}</td>)}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="footer">
                <Button onClick={closeModal} label={t("close")} dark/>
            </div>
        </div>
    );
}

export default PhotoShootingModalContent;