import * as React from "react";
import {uniqueId} from "../tools";
// import './Text.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'type', 'onKeyPress'];

function Text(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    const id = uniqueId();
    const colWidth = props.colWidth || 50;
    let className = `col-${colWidth}`;
    props.className && (className = `${className} ${props.className}`);
    return (
        <div className={className}>
            {props.label && <label htmlFor={id}>{props.label}</label>}
            <input type="text" id={id} autoComplete={props.value} {...getMainProps(inputMainProps)}
                   disabled={disabled}/>
        </div>
    );
}

export default Text;