import * as React from "react";
import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import UserForm from "../components/UserForm";
import useDetailData from "../hooks/useDetailData";
import config from "../config/config";

import './UserDetail.css';


function UserDetail(props) {
    const {isLoading, error, response} = useDetailData(config.apiRouteMap.getUser);

    return (
        <div className="UserDetail app__main">
            <Overview
                isLoading={isLoading}
                title={t('userDetail')}
                error={error}
            >
                <UserForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default UserDetail;
