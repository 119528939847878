import * as React from "react";

import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import GpuForm from "../components/GpuForm";

import './GpuAdd.css';

function GpuAdd(props) {

    return (
        <div className="GpuAdd app__main">
            <Overview title={t('newClient')}>
                <GpuForm history={props.history}/>
            </Overview>
        </div>
    );
}

export default GpuAdd;
