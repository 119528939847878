import React, {useState} from "react";
import t from "../hooks/useTranslation";
import Button from "../form/Button";
import AuthService from "../AuthService";
import "./RephotoModalContent.css";
import config from "../config/config";
import Error from "./Error";

const authService = new AuthService();

function RephotoModalContent(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [note, setNote] = useState('');

    const handleSave = async () => {
        try {
            await authService.put(config.apiRouteMap.markToRephoto(props.photoId), {note});
            setLoading(false);
            window.location.reload();
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }

    return (
        <div className="RephotoModalContent">
            <div className="body">
                <div>
                    <textarea onChange={e => setNote(e.target.value)} rows={10}/>
                </div>
                <div className="error">
                    {error && <Error error={error}/>}
                </div>
            </div>
            <div className="footer">
                <Button onClick={props.closeModal} label={t("close")} dark disabled={loading}/>
                <Button onClick={handleSave} label={t("save")} disabled={loading}/>
            </div>
        </div>
    );
}

export default RephotoModalContent;