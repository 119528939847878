import * as React from "react";

import Overview from "../components/OverviewTable/Overview";
import useOverviewData from "../hooks/useOverviewData";
import OverviewTable from "../components/OverviewTable/OverviewTable";
import {Link} from "react-router-dom";
import t from "../hooks/useTranslation";
import moment from "moment";
import config from "../config/config";
import AuthService from "../AuthService";
import OverviewPagination from "../components/OverviewTable/OverviewPagination";
import blankImg from "../img/360_logo.svg";
import {ReactComponent as CsvIcon} from "../img/csv-file-icon.svg";

import './Photos.css';
import {useState} from "react";

const authService = new AuthService();

const INITIAL_BRICKS_DATA = [
    {
        label: t('photoCount'),
        isLoading: true,
        dataKey: 'photoCount'
    },
    {
        label: t('dayPhotoCount'),
        isLoading: true,
        dataKey: 'dayPhotoCount'
    },
    {
        label: t('weekPhotoCount'),
        isLoading: true,
        dataKey: 'weekPhotoCount'
    },
    {
        label: t('monthPhotoCount'),
        isLoading: true,
        dataKey: 'monthPhotoCount',
    }
];

function Dashboard() {
    const {
        isLoading,
        error,
        response,
        bricksData,
        totalItemsCount,
        setFilter,
        filter,
        filterValues,
        getPreparedFilterQuery,
        pageInfo,
        setPageIndex,
        setError
    } = useOverviewData(config.apiRouteMap.photosOverview, config.apiRouteMap.listPhotos, INITIAL_BRICKS_DATA);
    const [isCsvLoading, setIsCsvLoading] = useState(false);

    const handleFilterChange = (key, value, type) => {
        let newFilter = {...filter};
        newFilter[key] = {type, value};
        if (
            (type === "text" && value === "")
            || (type === "select" && value === undefined)
        ) {
            delete newFilter[key];
        }
        setFilter(newFilter);
    };

    const columns = [
        {
            key: 'photo',
            title: t('photo'),
            valueRenderer: (row) =>
                <Link to={config.routeMap.getPhotoDetail(row._id)} target="_blank" rel="noopener noreferrer">
                    <div className='img360container'>
                        <img className="img360Hover" width={64} height={64}
                             onError={(props) => {
                                 const currentTarget = props.currentTarget;
                                 currentTarget.onerror = null; // prevents looping
                                 currentTarget.src = blankImg;
                             }}
                             src={`${row.photoDir}/images/0.jpg`} alt={"nahled360"}/>
                    </div>
                </Link>
        },
        {
            key: 'photoName',
            title: t('ftpFolder'),
            valueRenderer: photo => photo.photoName || '-',
            filter: {
                type: 'text',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('ftpFolder')
            }
        },
        {
            key: 'status',
            title: t('status'),
            valueRenderer: photo => {
                if (photo.status) {
                    if (photo.status === "shooting") {
                        const count = photo.expectedShootsCount ? `${photo.shootsCount}/${photo.expectedShootsCount}` : photo.shootsCount;
                        return `${t(`${photo.status}Status`)} (${count})`;
                    } else {
                        return t(`${photo.status}Status`);
                    }
                }
                return '';
            }
            // filter: {
            //     type: 'text',
            //     filterState: filter,
            //     onChange: handleFilterChange,
            //     label: t('writeText')
            // }
        },
        {
            key: 'createdAt',
            title: t('photoDate'),
            valueRenderer: ({createdAt}) => {
                return moment(createdAt).format('DD.MM.YYYY');
            },
            filter: {
                type: 'dateRange',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('photoDate')
            }
        },
        {
            key: 'uploadedAt',
            title: t('editedAt'),
            valueRenderer: photo => photo.uploadedAt ? moment(photo.uploadedAt).format('DD.MM.YYYY') : t('no'),
            filter: {
                type: 'select',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('editedAt'),
                emptyOption: true,
                options: [
                    {label: t('yes'), value: true},
                    {label: t('no'), value: false},
                ]
            }
        },
        {
            key: 'toReview',
            title: t('toReview'),
            valueRenderer: ({toReview}) => toReview ? t('yes') : t('no'),
            filter: {
                type: 'select',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('toReview'),
                emptyOption: true,
                options: [
                    {label: t('yes'), value: true},
                    {label: t('no'), value: false},
                ]
            }
        },
        // {
        //     key: 'checked',
        //     title: t('checked'),
        //     valueRenderer: ({checked}) => checked ? t('yes') : t('no'),
        //     filter: {
        //         type: 'bool',
        //         filterState: filter,
        //         onChange: handleFilterChange,
        //         label: t('choose')
        //     }
        // }
    ];

    const getClientFilterOptions = () => {
        if (filterValues?.clientList) {
            return filterValues.clientList.map(({name, _id}) => ({label: name, value: _id}));
        }
        return [];
    }

    if (authService.isSuperAdmin()) {
        columns.push({
            key: 'client',
            title: t('client'),
            valueRenderer: row => <label title={row.createdBy}>{row.client?.name}</label>,
            filter: {
                type: 'select',
                filterState: filter,
                emptyOption: true,
                onChange: handleFilterChange,
                label: t('client'),
                options: getClientFilterOptions()
            }
        });
    }

    const actions = [
        {
            component: row => <Link className="button secondary small" to={config.routeMap.getPhotoDetail(row._id)}
                                    target="_blank">
                {t('detail')}
            </Link>
        }
    ];

    const handlePageOnChange = (event) => {
        setPageIndex(event.selected);
    };

    const rowClassNameRenderer = (row) => {
        let className = 'Photos-item-row';
        if (row.checked) {
            className += ' checked';
        } else if (row.toRephoto) {
            className += ' rephoto';
        }
        return className;
    }

    const convertToCSV = (data) => {
        const csvRows = [];

        // get the headers
        const headers = Object.keys(data[0]).map(header => header === "photoName" ? 'EAN' : header).filter(header => header !== "_id");
        csvRows.push(headers.join(','));

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                header = header === "EAN" ? header = "photoName" : header;
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        // form final csv string
        return csvRows.join('\n');
    }

    const downloadCSV = async () => {
        if (!isCsvLoading) {
            try {
                setIsCsvLoading(true);
                // load all data for actual filter settings
                const {data} = await authService.post(config.apiRouteMap.loadForExport, {
                    filter: getPreparedFilterQuery(filter)
                });
                const csvData = convertToCSV(data);
                const blob = new Blob([csvData], {type: 'text/csv'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = 'export.csv';
                link.href = url;
                link.click();
            } catch (e) {
                setError(e);
                console.log(e);
            } finally {
                setIsCsvLoading(false);
            }
        }
    }

    return (
        <div className="Photos app__main">
            <Overview
                isLoading={isLoading}
                title={t('photoOverview')}
                introduction={t('introductionText')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={
                    <React.Fragment><strong>{t('items').toUpperCase()}</strong> ({totalItemsCount})
                        <CsvIcon className={`csv-icon ${isCsvLoading ? 'isLoading' : ''}`} onClick={downloadCSV}
                                 alt="csv-icon" title={t('downloadCSV')}/>
                    </React.Fragment>}
                               columns={columns} filter={filter} resetFilter={() => setFilter({})}
                               rows={response?.list} isLoading={isLoading} error={error}
                               rowClassNameRenderer={rowClassNameRenderer}/>
                {totalItemsCount > 0 &&
                <OverviewPagination response={response} onChange={handlePageOnChange}
                                    pageCount={Math.ceil(totalItemsCount / pageInfo.pageSize)}/>}
            </Overview>
        </div>
    );
}

export default Dashboard;
