import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';

import jwtDecode from "jwt-decode";
import useToken from './hooks/useToken';

import Login from "./routes/Login";
import Navbar from "./routes/Navbar";

import Clients from "./routes/Clients";
import LoggedUser from "./components/LoggedUser";
import config from "./config/config";
import Photos from "./routes/Photos";
import Machines from "./routes/Machines";
import Users from "./routes/Users";
import ClientAdd from "./routes/ClientAdd";
import ClientDetail from "./routes/ClientDetail";
import MachineAdd from "./routes/MachineAdd";
import MachineDetail from "./routes/MachineDetail";
import UserAdd from "./routes/UserAdd";
import UserDetail from "./routes/UserDetail";
import PhotoDetail from "./routes/PhotoDetail";

// FA icons
import '@fortawesome/fontawesome-svg-core/styles.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";

// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// react-toastify
import 'react-toastify/dist/ReactToastify.css';

import AppConfig from "./routes/AppConfig";
import AppConfigEdit from "./routes/AppConfigEdit";
import AuthService from "./AuthService";

import './App.css';
import Gpus from "./routes/Gpus";
import GpuAdd from "./routes/GpuAdd";
import GpuDetail from "./routes/GpuDetail";

const authService = new AuthService();
library.add(fas, fab);


function App() {
    const {token, setToken} = useToken();
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            if (token) {
                try {
                    const {data} = await authService.get(`/api/notification/loadByUser/${jwtDecode(token)._id}`);
                    if (data.notificationList) {
                        data.notificationList.forEach(notification => {
                            toast.warning(notification.message, {
                                position: "top-center",
                                autoClose: false,
                                closeOnClick: false,
                                theme: "dark",
                                onClose: () => markNotificationAsSeen(notification)
                            });
                        })
                    }
                } catch (err) {
                    setError(err);
                }
            }
        })()
    }, [token]);

    const markNotificationAsSeen = async (notification) => {
        await authService.put(`/api/notification/markAsSeen/${notification._id}`, {
            userId: jwtDecode(token)._id
        });
    }

    if (!token) {
        return <Login setToken={setToken}/>
    }

    return (
        <div className="app">
            <Router>
                <ToastContainer style={{width: "50%"}}/>
                <Navbar/>
                <section className="app__body">
                    <LoggedUser token={token} setToken={setToken}/>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return (
                                    token && <Redirect to={config.routeMap.photos}/>
                                );
                            }}
                        />

                        {/* index routes */}
                        <Route path={config.routeMap.photos} component={Photos}/>
                        <Route path={config.routeMap.machines} component={Machines}/>
                        <Route path={config.routeMap.clients} component={Clients}/>
                        <Route path={config.routeMap.users} component={Users}/>
                        <Route path={config.routeMap.appConfig} exact component={AppConfig}/>
                        <Route path={config.routeMap.gpuConfig} exact component={Gpus}/>

                        {/*/!* add routes *!/*/}
                        <Route path={config.routeMap.addClient} component={ClientAdd}/>
                        <Route path={config.routeMap.addMachine} component={MachineAdd}/>
                        <Route path={config.routeMap.addUser} component={UserAdd}/>
                        <Route path={config.routeMap.addGpu} component={GpuAdd}/>

                        {/*/!* edit routes *!/*/}
                        <Route path={config.routeMap.editPhoto} component={PhotoDetail}/>
                        <Route path={config.routeMap.editClient} component={ClientDetail}/>
                        <Route path={config.routeMap.editMachine} component={MachineDetail}/>
                        <Route path={config.routeMap.editUser} component={UserDetail}/>
                        <Route path={config.routeMap.editGpu} component={GpuDetail}/>
                        <Route path={config.routeMap.editAppConfig} component={AppConfigEdit}/>
                    </Switch>
                </section>
            </Router>
        </div>
    );
}

export default App;
