import * as React from "react";
import config from "../config/config";
import t from "../hooks/useTranslation";
import {useRef, useState} from "react";
import AuthService from "../AuthService";
import {download} from "../tools";
import '@webrotate360/imagerotator/build/css/all.css';
import Modal from "../components/Modal";
import RephotoModalContent from "../components/RephotoModalContent";

import './PhotoDetailControlSection.css';
import DownloadFullModalContent from "../components/DownloadFullModalContent";
import Button from "../form/Button";

const authService = new AuthService();

function PhotoDetailControlSection({photo}) {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();
    const [error, setError] = useState();
    const [uploadProgress, setUploadProgress] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [showToRephoto, setShowToRephoto] = useState(false);
    const [showDownloadFull, setShowDownloadFull] = useState(false);
    const inputRef = useRef(null);

    const onChangeFile = (event) => {
        setFile(event.target.files[0]);
    }

    const showOpenFileDialog = () => {
        inputRef.current.click();
    };

    const handleUploadProgress = progressEvent => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
        }
    }

    const upload360 = async () => {
        setIsLoading(true);
        setIsUploading(true);
        try {
            const data = new FormData();
            data.append('photoId', photo._id);
            data.append('data', file);
            await authService.put(config.apiRouteMap.uploadUpdated360(photo._id), data, null, handleUploadProgress);
            window.location.reload();
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
            setIsUploading(false);
        }
    }

    const sendToRepair = async () => {
        setIsLoading(true);
        try {
            await authService.put(config.apiRouteMap.markPhotoToReview(photo._id), null, null);
            window.location.reload();
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const renderUploadProgress = () => {
        if (isUploading) {
            if (uploadProgress === 100) {
                return (<label>{t('serverInProcess')}</label>);
            } else {
                return (<label>{t('uploadProgress')}: {uploadProgress}%</label>);
            }
        }
    }

    const onChangePhotoChecked = async () => {
        try {
            setIsLoading(true);
            await authService.post(config.apiRouteMap.markPhotoAsChecked, {id: photo._id});
            window.location.reload();
        } catch (err) {
            alert(err.message || 'Něco se pokazilo');
            setIsLoading(false);
            setError(err);
        }
    }

    const renderBasicActions = () => {
        if (authService.hasUserProfile(["Admin", "SuperAdmin"])) {
            return <>
                <h3>{t('basicActions')}</h3>
                <p>
                    <Button className="full" primary onClick={sendToRepair} label={t('sendToRepair')}
                            disabled={photo.toReview || isLoading}/>
                </p>
                <p>
                    <Button className="full" primary onClick={() => setShowToRephoto(!showToRephoto)}
                            disabled={isLoading} label={t('sendToRephoto')}/>
                </p>
                <p>
                    <Button className="full" primary onClick={onChangePhotoChecked}
                            disabled={isLoading} label={t('markAsChecked')}/>
                </p>
            </>;
        }
    }

    return (
        <aside className="app__detail__side">
            <Modal show={showToRephoto} htmlOpenClassName={"rephotoModalClassName"}
                   content={<RephotoModalContent closeModal={() => setShowToRephoto(false)} photoId={photo._id}/>}
                   modalTitle={t('sendToRephoto')} isModalOpen={(bool) => setShowToRephoto(bool)}/>
            <Modal show={showDownloadFull} htmlOpenClassName={"downloadModalClassName"}
                   content={<DownloadFullModalContent closeModal={() => setShowDownloadFull(false)} photo={photo}/>}
                   modalTitle={t('downloadFullConfig')} isModalOpen={(bool) => setShowDownloadFull(bool)}/>
            {renderBasicActions()}
            <h3>{t('downloadOptions')}</h3>
            <p>
                <Button className="full" base
                        onClick={() => setShowDownloadFull(!showDownloadFull)}
                        disabled={isLoading} label={t('downloadFull')}/>
            </p>
            <p>
                <Button className="full" base onClick={() => download(photo, 'only360', setIsLoading)}
                        disabled={isLoading} label={t('360Only')}/>
            </p>
            <p>
                <Button className="full" base onClick={() => download(photo, 'otherPhotosOnly', setIsLoading)}
                        disabled={isLoading} label={t('otherPhotoOnly')}/>
            </p>
            <h3>{t('fixedPhotos')}</h3>
            <p>
                <input type="file" name="file" onChange={onChangeFile} ref={inputRef} style={{display: "none"}}
                       accept={"zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"}/>
                <Button className="white full icon text-left"
                        onClick={() => showOpenFileDialog()} label={<>{t('selectFile')}
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor"
                              d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z"/>
                    </svg>
                </>}/>
            </p>
            {file && <label>Soubor: {file.name} ({Math.round(file.size / 1024 / 1024)} MB)</label>}
            {renderUploadProgress()}
            <p>
                <Button className="aside full text-left" onClick={upload360}
                        disabled={isLoading} label={t('uploadFixedPhoto')}/>
            </p>
        </aside>
    );
}

export default PhotoDetailControlSection;
