import * as React from "react";
import AppConfigForm from "../components/AppConfigForm";
import useFetch from "../hooks/useFetch";
import config from "../config/config";
import Loading from "../components/Loading";
import Error from "../components/Error";

import './AppConfigEdit.css';

function AppConfigEdit(props) {
    const {isLoading, error, response} = useFetch(config.apiRouteMap.appConfig);

    const renderContent = () => {
        if (error) {
            return <Error error={error}/>;
        } else {
            return <AppConfigForm data={response} history={props.history}/>;
        }
    }

    return (
        <div className="AppConfigEdit app__main">
            {isLoading ? <Loading/> : renderContent()}
        </div>
    );
}

export default AppConfigEdit;
