import * as React from "react";
import config from "../config/config";
import Overview from "../components/OverviewTable/Overview";
import t from "../hooks/useTranslation";
import MachineForm from "../components/MachineForm";
import useDetailData from "../hooks/useDetailData";

import './MachineDetail.css';

function MachineDetail(props) {
    const {isLoading, error, response} = useDetailData(config.apiRouteMap.getMachine);

    return (
        <div className="MachineDetail app__main">
            <Overview
                isLoading={isLoading}
                title={t('machineDetail')}
                error={error}
            >
                <MachineForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default MachineDetail;
