import * as React from "react";
import UserDefinedInputItemForm from "./UserDefinedInputItemForm";
import Button from "./Button";
import t from "../hooks/useTranslation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './UserDefinedInputsForm.css';

function UserDefinedInputsForm({onChange, value, label}) {
    const onChangeUserInputItem = (i, newInputItemValue) => {
        let newValue = value;
        newValue[i] = newInputItemValue;
        onChange(newValue);
    }

    const deleteItem = i => () => {
        let newValue = value;
        newValue.splice(i, 1);
        onChange(newValue);
    }

    const addUserInputItem = () => {
        let newValue = value;
        newValue.push({name: '', desc: '', dataType: 'string'});
        onChange(newValue);
    }

    function generateRows() {
        return React.Children.toArray(value.map((row, i) => (
                <div className="userDefinedRow">
                    <UserDefinedInputItemForm onChange={onChangeUserInputItem} value={row} index={i}/>
                    <FontAwesomeIcon icon="trash" onClick={deleteItem(i)}/>
                </div>
            )
        ));
    }

    return <div className="UserDefinedInputsForm">
        {label && <h4>{label}</h4>}
        {label && <div className="divider"/>}
        {generateRows()}
        <Button className="addMore" label={t('addUserInput')} onClick={addUserInputItem}/>
    </div>;

}

export default UserDefinedInputsForm;