import * as React from "react";
import {uniqueId} from "../../../tools";
import {ReactComponent as Icon} from '../../../img/ico-find.svg';

import './Text.css';

function Text(props) {

    const value = props.value || '';
    const id = uniqueId();
    return (
        <div className={`app__filter__item ico ${value !== "" && "mini"}`}>
            <label htmlFor={id}>{props.label}</label>
            <input type="text" id={id} value={value} onChange={props.onChange}/>
            <Icon/>
        </div>
    );
}

export default Text;