import React, {useEffect, useState} from "react";
import ReactModal from "react-modal";

import "./Modal.css";

function Modal(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const handleCloseModal = () => {
        props.isModalOpen(false);
        setShow(false);
    }

    return (
        <ReactModal
            bodyOpenClassName="ReactModal__Body--open CustomModal"
            isOpen={show}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={true}
            portalClassName={`ReactModalPortal ${props.htmlOpenClassName}`}
        >

            <div className="modal-header">
                <h5 className="modal-title">{props.modalTitle}</h5>
                <button onClick={handleCloseModal} type="button" className="close" data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            {props.content}


        </ReactModal>
    );
}

export default Modal;