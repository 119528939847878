import * as React from "react";
import Checkbox from "./Checkbox";

import './NestedForm.css';

function NestedForm({inputContainer, children, onChange, name: formName, value, label, desc}) {

    const childOnChange = (e, name, inputValue) => {
        let newValue = inputValue || e.target.value;
        let inputName = name || e.target.name;
        if (e?.target?.type === "checkbox") {
            newValue = e.target.checked;
        }
        const nestedData = value;
        nestedData[inputName] = newValue;
        onChange(null, formName, nestedData);
    }

    const onChangeNestedForm = (nestedFormName) => (e, nestedInputName, val) => {
        const newValue = value;
        newValue[nestedFormName] = val;
        onChange(null, formName, newValue);
    }

    const renderChildren = () => React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const childProps = {
                onChange: childOnChange
            };

            if (child.type === NestedForm) {
                childProps.onChange = onChangeNestedForm(child.props.name);
                childProps.value = value[child.props.name] ? value[child.props.name] : '';
                childProps.label = child.props.label;
            } else if (child.type === Checkbox) { // check if input type is checkbox
                childProps.value = value[child.props.name] ? value[child.props.name] : false;
            } else {
                childProps.value = value[child.props.name] ? value[child.props.name] : '';
            }
            return React.cloneElement(child, childProps);
        }
        return child;
    });

    const _renderInputs = () => {
        if (inputContainer) {
            return inputContainer(renderChildren())
        }
        return renderChildren();
    }

    return <React.Fragment>
        {label && <h4>{label}</h4>}
        {label && <div className="divider"/>}
        {desc && <label className="desc">{desc}</label>}
        {_renderInputs()}
    </React.Fragment>
}

export default NestedForm;