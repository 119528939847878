import React from 'react';
import OverviewBrick from "./OverviewBrick";
import Error from "../Error";

import './Overview.css';
import {getBrickStyles} from "../../tools";

function Overview(props) {

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className="app__welcome">{props.introduction}</div>
                <div className="app__title"><h1>{props.title}</h1></div>
                {
                    props.bricks &&
                    <section className="app__bricks">
                        {
                            props.bricks.map((bricksData, i) => <OverviewBrick key={`overviewBrick${i}`}
                                                                               data={bricksData}
                                                                               style={getBrickStyles(i)}/>)
                        }
                    </section>
                }
                {props.error && <Error error={props.error}/>}
            </React.Fragment>
        );
    }

    return React.Children.toArray([
        renderContent(),
        props.children
    ]);
}

export default Overview;