import * as React from "react";
import config from "../config/config";
import {Link} from "react-router-dom";
import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/OverviewTable/Overview";
import OverviewTable from "../components/OverviewTable/OverviewTable";
import t from "../hooks/useTranslation";
import moment from "moment";

import './Clients.css';

function Gpus() {
    const {
        isLoading,
        error,
        response,
        bricksData
    } = useOverviewData(config.apiRouteMap.gpu);

    const columns = [
        {
            key: 'uid',
            title: t('uid')
        },
        {
            key: 'name',
            title: t('name'),
            linkRenderer: ({_id, name}) => ({
                route: config.routeMap.getGpuDetail(_id),
                label: name
            })
        },
        {
            key: 'ip',
            title: t('ip')
        },
        {
            key: 'priority',
            title: t('priority')
        },
        {
            key: 'clientList',
            title: t('clients'),
            valueRenderer: (row) => row.clientList.map(client => client.name).join(", ")
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getGpuDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [<Link className="button secondary" to={config.routeMap.addGpu}>{t('newGpu')}</Link>];

    return (
        <div className="Gpus app__main">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('GpuAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} columns={columns}
                               rows={response?.list}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Gpus;
