import React, {useState} from "react";
import CheckboxAccordion from "./CheckboxAccordion";

import "./MachineSelector.css";

const MachineSelector = ({clientMachineMap, onChange, value}) => {
    const [selectedMachineList, setSelectedMachineList] = useState(value);

    const handleSelect = (selected, machineIdList) => {
        let newSelectedMachineList = new Set(selectedMachineList);
        if (selected) {
            machineIdList.forEach(id => newSelectedMachineList.add(id));
        } else {
            machineIdList.forEach(id => newSelectedMachineList.has(id) && newSelectedMachineList.delete(id));
        }
        setSelectedMachineList([...newSelectedMachineList]);
        onChange(null, 'machineList', [...newSelectedMachineList]);
    }

    return <div className="MachineSelector">
        {
            Object.entries(clientMachineMap).map(([clientId, clientData]) => {
                return <CheckboxAccordion label={clientData.name} items={clientData.machineList} key={clientId}
                                          selected={selectedMachineList} onChange={handleSelect}/>;
            })
        }
    </div>;
};

export default MachineSelector;
