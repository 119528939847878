import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {mergeObjects, onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";
import {useParams} from "react-router-dom";

import './AppConfigForm.css';
import useFetch from "../hooks/useFetch";

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    invoiceEmailList: [],
    reportEmailList: []
}

function AppConfigForm(props) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        const loadAppConfig = async () => {
            try {
                let {data} = await authService.get(config.apiRouteMap.appConfig);
                data = mergeObjects(INITIAL_FORM_DATA, data.appConfig);
                setFormData(data);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
            }
        }
        loadAppConfig();
    }, []);

    const onSubmit = async () => {
        try {
            setIsLoading(true);

            await authService.put(config.apiRouteMap.appConfig, formData);
            setError(null);
            props.history.push(config.routeMap.appConfig);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <div className="customGrid">
                            <Forms.List name="invoiceEmailList" value={formData.invoiceEmailList}
                                        label={t('invoiceEmails')}
                                        onChange={onChange(setFormData)}/>
                            <Forms.List name="reportEmailList" value={formData.reportEmailList}
                                        label={t('reportEmails')}
                                        onChange={onChange(setFormData)}/>
                        </div>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="AppConfigForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default AppConfigForm;