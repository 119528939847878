import React, {useState} from "react";
import t from "../hooks/useTranslation";
import Button from "../form/Button";
import AuthService from "../AuthService";
import config from "../config/config";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange} from "../tools";

import "./EditPhotoModalContent.css";

const authService = new AuthService();

function EditPhotoModalContent({photo, closeModal}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [formData, setFormData] = useState({
        photoName: photo.photoName,
        customName: photo.customName || ''
    });

    const handleSave = async () => {
        try {
            await authService.put(config.apiRouteMap.updatePhoto(photo._id), formData);
            setLoading(false);
            window.location.reload();
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }

    return (
        <div className="EditPhotoModalContent">
            <div className="body">
                <div>

                    <Forms.Text label={t('photoCode')} value={formData.photoName} name='photoName'
                                onChange={onChange(setFormData)} disabled={loading} colWidth={100}/>
                </div>
                <div className="error">
                    {error && <Error error={error}/>}
                </div>
            </div>
            <div className="footer">
                <Button onClick={closeModal} label={t("close")} dark disabled={loading}/>
                <Button onClick={handleSave} label={t("save")} disabled={loading}/>
            </div>
        </div>
    );
}

export default EditPhotoModalContent;