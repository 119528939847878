import * as React from "react";
import {useEffect} from "react";
import WR360 from '@webrotate360/imagerotator';
import '@webrotate360/imagerotator/build/css/all.css';
import './Photo360Viewer.css';

let viewerApi;

function Photo360Viewer({photo}) {

    useEffect(() => {
        if (photo) {
            const viewer = WR360.ImageRotator.Create("wr360PlayerId");
            viewer.settings.configFileURL = `${photo.photoDir}/index.xml`;
            viewer.settings.responsiveBaseWidth = 500;
            viewer.settings.responsiveMinHeight = 500;
            viewer.runImageRotator();

            viewer.settings.apiReadyCallback = (api) => {
                viewerApi = api;
            }
        }

        // unmount
        return () => {
            if (viewerApi) {
                viewerApi.delete();
            }
        }
    }, [photo]);

    return (
        <div className="Photo360Viewer">
            <div id="wr360PlayerId" className="wr360_player"/>
        </div>
    );
}

export default Photo360Viewer;
