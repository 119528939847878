import config from "./config/config";
import AuthService from "./AuthService";
import FileSaver from 'file-saver';

const authService = new AuthService();

export const formatPrice = (value) => value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

export const objectId = function () {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

export const onChange = (setFormData) => (e, key, val) => {
    let value;
    let name;
    if (e) {
        name = e.target.name;
        switch (e.target.type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            case 'number':
                value = e.target.value ? parseFloat(e.target.value) : '';
                break;
            case 'file':
                value = e.target.files;
                break;
            default:
                value = e.target.value;
        }
    } else {
        value = val;
        name = key;
    }
    setFormData(prevState => ({
            ...prevState,
            [name]: value
        }
    ));
}

export const mergeObjects = (obj1, obj2) => {
    const tmp = {};
    Object.entries(obj1).forEach(([key, val]) => {
        // is object
        if (!Array.isArray(val) && typeof val === 'object' && val !== null) {
            if (obj2.hasOwnProperty(key)) {
                tmp[key] = mergeObjects(val, obj2[key]);
            } else {
                tmp[key] = obj1[key];
            }
        } else if (Array.isArray(val)) {
            tmp[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
        } else {
            tmp[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
        }
    });
    return tmp;
}

export const loadInterfaceColors = () => {
    let interfaceColors = localStorage.getItem("interfaceColors");
    if (interfaceColors && interfaceColors !== "") {
        return JSON.parse(interfaceColors);
    }
}

export const getNavbarStyles = () => {
    const interfaceColors = loadInterfaceColors();
    if (interfaceColors?.menu?.background) {
        return {backgroundColor: interfaceColors.menu.background};
    }
}

export const getMenuIconColor = (isActive) => {
    const interfaceColors = loadInterfaceColors();
    if (interfaceColors?.menuItem?.icon && interfaceColors?.menuItemActive?.icon) {
        return isActive ? interfaceColors.menuItemActive.icon : interfaceColors.menuItem.icon;
    }
}

export const getNavbarButtonStyles = (isActive) => {
    const interfaceColors = loadInterfaceColors();
    const styles = {};
    if (interfaceColors?.menuItem?.background && interfaceColors?.menuItemActive?.background) {
        styles.backgroundColor = isActive ? interfaceColors.menuItemActive?.background : interfaceColors.menuItem?.background;
    }

    if (interfaceColors?.menuItem?.text && interfaceColors?.menuItemActive?.text) {
        styles.color = isActive ? interfaceColors.menuItemActive?.text : interfaceColors.menuItem?.text;
    }

    return styles;
}

export const getButtonStyles = (variant, interfaceColors = loadInterfaceColors()) => {
    const styles = {};
    if (interfaceColors?.[variant]?.text) {
        styles.color = interfaceColors[variant].text;
    }
    if (interfaceColors?.[variant]?.background) {
        styles.backgroundColor = interfaceColors[variant].background;
    }
    return styles;
}

const brickVariants = {
    0: 'brick14',
    1: 'brick2',
    2: 'brick3',
    3: 'brick14',
};
export const getBrickStyles = (n, interfaceColors = loadInterfaceColors()) => {
    const styles = {};
    const variant = brickVariants[n];
    if (interfaceColors?.[variant]?.background) {
        styles.div = {backgroundColor: interfaceColors[variant].background};
    }
    if (interfaceColors?.[variant]?.desc) {
        styles.label = {color: interfaceColors[variant].desc};
    }
    if (interfaceColors?.[variant]?.value) {
        styles.value = {color: interfaceColors[variant].value};
    }
    return styles;
}

export const getButtonStylesForIcon = () => {
    const interfaceColors = loadInterfaceColors();
    return interfaceColors?.button?.background;
}

export const getLogoPath = () => {
    return localStorage.getItem("logo");
}

export const prepareFormData = formData => {
    const newData = {};
    Object.entries(formData).forEach(([key, val]) => {
        if (val && val !== "") {
            newData[key] = val;
        }
    });
    return newData;
}

export const download = async (row, type, setDisabled, downloadWithViewer = false, fullWithOriginals = false) => {
    setDisabled(true);
    const devPrefix = process.env.NODE_ENV === "development" ? "http://localhost:3001" : '';
    if (type === "full") {
        const res = await authService.get(`${config.apiRouteMap.downloadFull(row._id)}?withViewer=${downloadWithViewer}&withOriginals=${fullWithOriginals}`);
        FileSaver.saveAs(`${devPrefix}${res.data.zipFileUrl}`, `${row.photoName}-full.zip`);
    } else if (type === "only360") {
        const res = await authService.get(config.apiRouteMap.download360(row._id));
        FileSaver.saveAs(`${devPrefix}${res.data.zipFileUrl}`, `${row.photoName}-360.zip`);
    } else if (type === "otherPhotosOnly") {
        const res = await authService.get(config.apiRouteMap.downloadOtherPhotos(row._id));
        FileSaver.saveAs(`${devPrefix}${res.data.zipFileUrl}`, `${row.photoName}-otherPhotos.zip`);
    }
    setDisabled(false);
}

export const uniqueId = (prefix = 'id-') => prefix + Math.random().toString(16).slice(-8);

export const inputsContainer = content => <div className="row">{content}</div>;